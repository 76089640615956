::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: #252a44;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background: #007bff;
  border-radius: 6px;
}
