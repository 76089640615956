html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiGrid-item {
  position: relative;
}

#slide-container {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  border: 0;
}

@media screen {
  #slide-container {
    bottom: 0;
    right: 0;
    overflow: auto;
  }
}

#slide-container .slide img {
  width: 100vw;
  height: inherit;
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
  #slide-container .slide {
    height: 32vh;
  }
}

@media only screen and (max-width: 600px) and (orientation: landscape) {
  #slide-container .slide {
    height: 100vh;
  }
}

@media only screen and (min-width: 600px) {
  #slide-container .slide {
    height: 50vh;
  }
}

@media only screen and (min-width: 768px) and (orientation: portrait) {
  #slide-container .slide {
    height: 40vh;
  }
}

@media only screen and (min-width: 768px) and (orientation: landscape) {
  #slide-container .slide {
    height: 100vh;
  }
}

@media only screen and (min-width: 992px) {
  #slide-container .slide {
    height: 80vh;
  }
}

@media only screen and (min-width: 1200px) {
  #slide-container .slide {
    height: 100vh;
  }
}
